import React from "react";

function Footer() {
  return (
    <footer className="bg-dw-red text-white py-8 relative bottom-0 px-3">
      <div className="flex flex-col-reverse gap-4 lg:flex-row items-center justify-between max-w-7xl mx-auto">
      <div>
      <p className="">&copy; {new Date().getFullYear()} SDPII</p>
      </div>
      <div className="flex justify-center gap-2">
        <a
          href="https://www.linkedin.com/company/delawarebelux/"
          target="_blank"
          rel="noreferrer noopener"
          className="text-white fill-current hover:text-gray-200"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 47 47"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M13.9 17.2H8.2c-1.1 0-2 .9-2 2.1v20.6c0 .5.2 1.1.6 1.4.4.4.9.6 1.4.6h5.7c1.1 0 2-.9 2-2.1V19.2c0-.5-.2-1.1-.6-1.4-.4-.4-.9-.6-1.4-.6zm-2.1 20.5h-1.7V21.3h1.7v16.4zm-.9-32C8 5.7 5.7 8 5.7 10.9c0 2.8 2.3 5.2 5.2 5.2s5.2-2.3 5.2-5.2c0-2.8-2.4-5.2-5.2-5.2zm0 6.2c-.7 0-1.2-.4-1.2-1s.5-1 1.2-1c.6 0 1.2.5 1.2 1-.1.5-.6 1-1.2 1zm28.4 9.8c-1.3-3.2-3.9-4.9-7.9-4.9-1.9 0-3.5.6-4.7 1.4-.3-.6-1-1.1-1.7-1.1h-5.5c-1.1 0-2 .9-2 2.1v20.5c0 .5.2 1.1.6 1.4.4.4.9.6 1.4.6h6.2c1.1 0 2-.9 2-2.1v-8.3c.2-2.2.9-2.1 1.4-2.1.6 0 1.2.4 1.3 2.1.1 1.6 0 8.1 0 8.2 0 .6.2 1.1.6 1.5s.9.6 1.4.6H38c.1 0 .3.1.4.1 1.1 0 2-.9 2-2v-9.2c-.1-3.1-.1-6.2-1.1-8.8zm-5 9.6c-.1-3.5-2.1-6-5.1-6.1-2.4-.2-3.8 1-4.4 2.1-.7 1-1.1 2.3-1.2 3.9v6.5h-2.2V21.3H23v.9c0 .7.1 1.9 1.4 2.4.8.3 1.7 0 2.2-.6l.1-.1c1-1.2 2.4-3 4.7-2.9 4.6.1 5 2.6 5 9.7v7.1h-2c0-2.3 0-5.4-.1-6.5z" />
          </svg>
        </a>
        <a
          href="https://www.facebook.com/delawareBeLux"
          target="_blank"
          rel="noreferrer noopener"
          className="text-white fill-current hover:text-gray-200"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 47 47"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M34.7 7.8c0-1.2-.9-2.1-2.1-2.1h-7.4c-5.3.1-9.4 4.6-9.4 10.1v3h-1.6c-1.2 0-2.1.9-2.1 2.1v5.5c0 .6.2 1.1.6 1.5s.9.6 1.5.6h1.6l-.1 11c0 .6.2 1.1.6 1.5s.9.6 1.5.6H25c.1 0 .3.1.5.1 1.2 0 2.1-.9 2.1-2.1v-11h4c1.2 0 2.1-.9 2.1-2.1v-5.8c0-1.2-.9-2.1-2.1-2.1h-4v-2.2l.1-.1c1.2-.1 3.8 0 4.8 0 .6 0 1.1-.2 1.5-.6s.6-.9.6-1.5V7.8c.1.1.1.1.1 0zm-7.3 4.4c-2.2.2-3.5 2-3.8 3.7v4.9c0 1.2.9 2.1 2.1 2.1h4v1.6h-4c-1.2 0-2.1.9-2.1 2.1v10.9h-3.5l.1-11.1c0-.6-.2-1.1-.6-1.5s-.9-.6-1.5-.6h-1.6V23h1.6c1.2 0 2.1-.9 2.1-2.1v-5.1c0-3.6 2.7-5.9 5.3-5.9h5.2v2.3h-3.3z" />
          </svg>
        </a>
        <a
          href="https://twitter.com/delawareBeLux"
          target="_blank"
          rel="noreferrer noopener"
          className="text-white fill-current hover:text-gray-200"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 47 47"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M45.1 12.1c-.6-.7-1.6-1-2.4-.6-.1.1-.3.1-.4.2.2-.3.4-.5.6-.8.2-.4.5-.7.8-1.2.6-.8.5-1.9-.2-2.7-.7-.7-1.8-.9-2.6-.4-1.1.6-2.2 1.1-3.4 1.4-1.8-1.6-4.2-2.4-6.6-2.3h-.2c-2.6 0-5.2 1.1-7 3-1.8 1.7-2.8 4.1-3 6.6-2.1-.3-4.2-1-6.2-2l-1.8-1C11 11.1 9.4 9.7 8 8c-.1-.2-.3-.3-.5-.4-.4-.3-.9-.5-1.4-.5-.7.1-1.3.4-1.7 1a10.28 10.28 0 0 0-.5 9.2c-.5.4-.9 1.1-.9 1.7v.1c0 2.4.8 4.7 2.3 6.5.2.3.4.5.7.8-.2.4-.2 1-.1 1.5.8 2.3 2.3 4.3 4.3 5.5-1.5.5-3.1.8-4.8.8h0c-.6 0-1.2 0-1.7-.1-1.1-.1-2.2.7-2.3 1.8-.1.9.4 1.8 1.3 2.2 3.9 2.5 8.5 3.8 13.2 3.8h.4c2.9 0 5.8-.5 8.5-1.4 2.6-.9 5-2.2 7.1-4 1.9-1.7 3.6-3.6 5-5.7s2.4-4.4 3.2-6.8c.6-2 .9-4 1-6.1.1-.1.2-.1.3-.2 1.2-.7 2.9-1.8 3.8-3.1.5-.8.5-1.8-.1-2.5zm-8.2 4.8c0 2-.3 4-.8 5.9-.6 2-1.5 3.9-2.6 5.7a24.13 24.13 0 0 1-4.2 4.8c-1.7 1.4-3.7 2.5-5.8 3.3-2.4.8-4.8 1.3-7.4 1.2-1.4 0-2.8-.1-4.2-.4 1.8-.7 3.5-1.6 5-2.8.7-.6 1-1.5.7-2.3-.2-.6-.7-1.1-1.4-1.3-.2-.1-.5-.2-.7-.2-.7 0-1.4-.2-2-.4-.6-.3-1.2-.6-1.7-1.1.1 0 .3-.1.4-.1.9-.2 1.6-1.1 1.6-2.1 0-.9-.6-1.6-1.4-1.9-.1-.2-.3-.2-.4-.2l-.9-.3c-.9-.4-1.7-1-2.3-1.7h0c-.2-.2-.4-.5-.6-.8.2 0 .5.1.7.1.9 0 1.8-.6 2.1-1.5s-.1-1.9-.8-2.4c-.8-.5-1.5-1.3-1.9-2.1-.5-.8-.7-1.8-.7-2.8 1.2 1.1 2.5 2 3.9 2.8.5.3 1 .6 1.5.8 3.1 1.6 6.7 2.5 10.1 2.7.7 0 1.3-.2 1.7-.7s.6-1.2.4-1.8c-.1-.5-.2-.9-.2-1.4h0c0-1.6.6-3.1 1.7-4.2l.1-.1c1.1-1.1 2.6-1.8 4.2-1.7h.2c1.6 0 3.1.7 4.1 1.9.3.3.7.5 1.1.6-.1.2-.1.5 0 .7.1.7.5 1.3 1.1 1.6.2.1.3.2.5.3-.8.5-1.1 1-1.1 1.9z" />
          </svg>
        </a>
        <a
          href="https://www.instagram.com/delawarebelux/"
          target="_blank"
          rel="noreferrer noopener"
          className="text-white hover:text-gray-200 "
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 47 47"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M32.2 5.7H14.8c-5.1 0-9.2 4.1-9.2 9.2v17.7c0 5.1 4.1 9.2 9.2 9.2h17.3c5.1 0 9.3-4.2 9.3-9.3V14.9c0-5-4.1-9.2-9.2-9.2zm5 26.9c0 2.8-2.3 5-5 5H14.8a4.95 4.95 0 0 1-5-5V14.9a4.95 4.95 0 0 1 5-5h17.3a4.95 4.95 0 0 1 5 5v17.7zM23.3 13.9a10.5 10.5 0 1 0 0 21 10.5 10.5 0 1 0 0-21zm0 16.7c-3.5 0-6.3-2.8-6.3-6.3s2.8-6.3 6.3-6.3 6.3 2.8 6.3 6.3-2.8 6.3-6.3 6.3zm9-17.7c-1.2 0-2.1.9-2.1 2.1s.9 2.1 2.1 2.1 2.1-.9 2.1-2.1-.9-2.1-2.1-2.1z" />
          </svg>
        </a>
        <a
          href="https://www.youtube.com/user/DelawareConsulting"
          target="_blank"
          rel="noreferrer noopener"
          className="text-white hover:text-gray-200 "
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 47 47"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M35.6 6.6H11.4A10.07 10.07 0 0 0 1.3 16.7v14.1a10.07 10.07 0 0 0 10.1 10.1h24.2a10.07 10.07 0 0 0 10.1-10.1v-14c0-5.6-4.5-10.2-10.1-10.2zm5.9 24.2c0 3.3-2.7 5.9-5.9 5.9H11.4c-3.3 0-5.9-2.7-5.9-5.9v-14c0-3.2 2.7-5.9 5.9-5.9h24.2c3.3 0 5.9 2.7 5.9 5.9v14zm-9.8-8.5l-.2-.2-12.2-7c-.7-.4-1.4-.4-2.1 0s-1.1 1.1-1.1 1.8v13.7c0 .4 0 .9.3 1.3.2.3.4.5.6.7.1.1.2.1.3.1s.1.1.2.1.1 0 .2.1c.2 0 .3.1.5.1s.3 0 .5-.1h.2c.1 0 .2-.1.3-.1h.1l12.1-6.9a1.96 1.96 0 0 0 .9-.9c.5-.9.2-2.1-.6-2.7zm-5.5 1.6l-5.9 3.3v-6.7l5.9 3.4z" />
          </svg>
        </a>
      </div>
      </div>
    </footer>
  );
}

export default Footer;
