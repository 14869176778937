import React, { useState, useEffect, useMemo } from 'react';
import useBestellingen from '../api/bestelling';
import { FaTruck, FaCheckCircle, FaBox, FaHome } from 'react-icons/fa';
import BestellingDetails from './BestellingDetails';

const TrackOrderPage = () => {
  const {getByTnt} = useBestellingen();
  const [fouteInvoer, setFouteInvoer] = useState(false);
  const [trackCode, setTrackCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [validationError, setValidationError] = useState(false);
  const [bestelling, setBestelling] = useState(null);
  const [filledPoints, setFilledPoints] = useState([]);
  const points = useMemo(() => ['geplaatst', 'verwerkt', 'onderweg', 'geleverd'], []);

  const iconMap = {
    geplaatst: FaCheckCircle,
    verwerkt: FaBox,
    onderweg: FaTruck,
    geleverd: FaHome,
  };

  const handleTrackCodeChange = (event) => {
    setTrackCode(event.target.value);
  };

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleTrackOrder = async (event) => {
    event.preventDefault();

    if (!trackCode || !verificationCode) {
      setValidationError(true);
      return;
    }
    const data = await getByTnt(trackCode, verificationCode);
    setBestelling(data);
     if (bestelling === null) {
      setFouteInvoer(true);
    }else {
      setFouteInvoer(false);
    }
  };

  useEffect(() => {
  if (bestelling !== null) {
    let currentPoint;
    const status = bestelling.bestelling[0].STATUS;
    if (status === 'geplaatst') {
      currentPoint = 1;
    } else if (status === 'verwerkt') {
      currentPoint = 2;
    } else if (status === 'onderweg') {
      currentPoint = 3;
    } else {
      currentPoint = 4;
    }

    setFilledPoints(points.slice(0, currentPoint + 1));
  }
}, [bestelling, points]);

  

  return (
    <div className="min-h-screen  max-w-7xl py-12 px-3 lg:mx-auto">
      <h1 className="text-3xl font-light text-black uppercase mb-8 text-center tracking-widest lg:text-left">
        TRACK & TRACE
          </h1>
      {bestelling === null ? (
        
        <div className="w-full bg-white border rounded shadow-lg p-8">
          <h2 className="uppercase text-lg font-light tracking-widest mb-2">Traceer een bestelling</h2>
          <form onSubmit={handleTrackOrder}>
            <div className="mb-4">
              <label className="block">Track & Trace Code:</label>
              <input type="text" value={trackCode} onChange={handleTrackCodeChange} className="border border-gray-300 rounded p-2 w-full" />
            </div>
            <div className="mb-4">
              <label className="block">Verificatiecode:</label>
              <input type="text" value={verificationCode} onChange={handleVerificationCodeChange} className="border border-gray-300 rounded p-2 w-full" />
              {validationError && <p className="text-red-500">Geef de track & trace code en de verificatiecode in.</p>}
              {fouteInvoer && <p className="text-red-500">Geef een geldige track&trace code en verificatiecode in</p>}
            </div>
    
            <button type="submit" className="bg-dw-red text-white px-4 py-2 rounded hover:contrast-200 transition duration-300">Traceer bestelling</button>
          </form>
        </div>
      ) : (
        
        <div className="flex items-center justify-center bg-white pt-10 max-w-7xl mx-auto px-3">
          <div className="flex items-center w-full">
            {points.map((point, index) => {
              const Icon = iconMap[point];
              const isFilled = filledPoints.includes(point);
              const lineStyle = {
                width: `${((index - filledPoints[0]) / (filledPoints.length - 1)) * 100}%`,
                marginLeft: `${(filledPoints[0] / (filledPoints.length - 1)) * 100}%`,
              };
              const lineColor = isFilled ? 'bg-dw-red' : 'bg-gray-300';

              return (
                <React.Fragment key={point}>
                  {index !== 0 && (
                    <div
                      className={`h-2 w-96 ${lineColor} border-black`}
                      style={{ marginTop: '40px', marginBottom: '16px', marginLeft: '-50px', marginRight: '-10px' }}
                    >
                      <div className={`absolute h-2 ${lineColor} border-black`} style={lineStyle} />
                    </div>
                  )}
                  <div className={`relative ${index !== points.length - 1 ? 'mr-8' : ''}`}>
                    <div className="flex flex-col items-center">
                      <div className="relative mt-8">
                        <Icon
                          className={`text-dw-red absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full text-4xl lg:text-7xl`}
                        />
                        <div
                          className={`h-12 w-12 flex items-center justify-center rounded-full border ${lineColor} border-black border-2`}
                          style={{ marginTop: '20px' }}
                        />
                      </div>
                      <span className={`text-sm mt-2 ${isFilled ? 'text-dw-red' : 'text-gray-400'}`}>{point}</span>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
      {bestelling && bestelling.bestelling && bestelling.bestelling[0] ? (
  <BestellingDetails param={bestelling} />
) : (
  null
)}

    </div>
  );
};

export default TrackOrderPage;
