import React, { useState, useEffect } from "react";
import { CartState } from "../Context/Context";
import useBestellingen from "../api/bestelling";
import * as dozenApi from "../api/dozen";
import Error from "../Components/Error";
import Loader from "../Components/Loader";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import * as gemeenteApi from "../api/gemeente";

const OrderForm = () => {
  const navigate = useNavigate();
  const { plaatsen } = useBestellingen();
  const {
    state: { cart = [] },
    dispatch,
  } = CartState();
  const [ORDERBEDRAG, setORDERBEDRAG] = useState(0);
  const [dozen, setDozen] = useState([]);
  const [selectedDoos, setSelectedDoos] = useState(null);
  const [postcode, setPostcode] = useState("");
  const [gemeente, setGemeente] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
 const [countries, setCountries] = useState([]);
  const [land, setLand] = useState("");
  const handleChange = (event) => {
    const selectedDoosObject = JSON.parse(event.target.value);
    setSelectedDoos(selectedDoosObject);
  };
  useEffect(() => {
    const fetchDozen = async () => {
      try {
        setLoading(true);
        setError(null);
        const dataDozen = await dozenApi.getAll();
        setDozen(dataDozen);
        setSelectedDoos(dataDozen[0]);
        setCountries(["België" , "Nederland"]);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    const calcSubORDERBEDRAG = cart.reduce(
      (acc, curr) => acc + Number(curr.price) * curr.qty,
      0
    );
    fetchDozen();
    setORDERBEDRAG(calcSubORDERBEDRAG);
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    const fetchGemeente = async () => {
      try {
        setLoading(true);
        setError(null);
        const dataGemeente = await gemeenteApi.getGemeente(postcode);
        setGemeente(dataGemeente);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (postcode.length === 4) {
      fetchGemeente();
    }
  }, [postcode]);

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1; // Note: months are zero-based
  const year = today.getFullYear();

  // Pad the day and month with leading zeros if needed
  const formattedDay = String(day).padStart(2, "0");
  const formattedMonth = String(month).padStart(2, "0");

  // Form the date string in the desired format
  const ORDERDATUM = `${formattedDay}/${formattedMonth}/${year}`;

  const handleSubmit = async(event) => {
    try {
      event.preventDefault();
      const formData = new FormData(event.target);
      const straat = formData.get("street");
      const nummer = formData.get("number");
      const postcode = formData.get("postcode");
      const gemeente = formData.get("gemeente");
      const land = formData.get("country");
      const LEVERADRES = `${straat} ${nummer} ${postcode} ${gemeente} ${land}`;
      const DOOS_DOOSID = selectedDoos.DOOSID;
      const CURRENCY = "EUR";
      const PRODUCTEN = cart.map((prod) => ({
        id: prod.id,
        qty: prod.qty,
      }));

     
      
      const bestelling = {
        CURRENCY,
        LEVERADRES,
        ORDERBEDRAG,
        ORDERDATUM,
        DOOS_DOOSID,
        PRODUCTEN,
      };
      const orderId = await plaatsen(bestelling);
      dispatch({
        type: "CLEAR_CART",
      });
      navigate("/order/success", { state: { orderId } });
    } catch (error) {
      console.error(error);
    }
  };

  if (error) {
    return (
      <div className="min-h-screen max-w-7xl py-12 px-3 lg:mx-auto">
        <Loader loading={loading} />
        <Error error={error} />
      </div>
    );
  }

  return (
    <div className=" min-h-[calc(100vh-168px)] max-w-7xl py-12 px-3 lg:mx-auto">
      <h1 className="text-3xl font-light text-black mb-8 uppercase text-center lg:text-left tracking-widest">
        Bestelling plaatsen
      </h1>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-4 lg:grid-cols-2 lg:gap-6">
        <div>
          <form onSubmit={handleSubmit} className="">
            <Loader loading={loading} />
            <Error error={error} />
            <h2 className="uppercase text-lg font-light tracking-widest mb-2">
              Gegevens
            </h2>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 sm:gap-4 lg:grid-cols-1 lg:gap-6">
              <div className="">
                <label
                  htmlFor="street"
                  className="block text-gray-700 font-bold mb-1"
                >
                  Straat<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  placeholder="Straat"
                  required
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                />
              </div>
              <div className="">
                <label
                  htmlFor="number"
                  className="block text-gray-700 font-bold mb-1"
                >
                  Nummer<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="number"
                  name="number"
                  placeholder="Nummer"
                  required
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                />
              </div>
              <div className="">
                <label
                  htmlFor="postcode"
                  className="block text-gray-700 font-bold mb-1"
                >
                  Postcode<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="postcode"
                  name="postcode"
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                  placeholder="Postcode"
                  required
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                />
              </div>
              <div className="">
                <label
                  htmlFor="gemeente"
                  className="block text-gray-700 font-bold mb-1"
                >
                  Gemeente<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="gemeente"
                  name="gemeente"
                  value={gemeente}
                  onChange={(e) => {
                    e.preventDefault();
                    setGemeente(e.target.value);
                  }}
                  placeholder="Gemeente"
                  required
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                />
              </div>
              <div className="">
                <label
                  htmlFor="country"
                  className="block text-gray-700 font-bold mb-1"
                >
                  Land<span className="text-red-500">*</span>
                </label>
                <select
                      id="country"
                      name="country"
                      placeholder="Land"
                      value={land}
                      onChange={(e) => setLand(e.target.value)}
                      required
                      className="border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="" disabled defaultValue hidden>
                        Selecteer een land
                      </option>
                      {countries.map((country) => (
                        <option
                          key={country}
                          value={country}
                        >
                          {country}
                        </option>
                      ))}
                    </select>
              </div>
            </div>
            <div className="mt-6">
              <h2 className="uppercase text-lg font-light tracking-widest mb-2">
                Verpakking
              </h2>
              <label
                htmlFor="packaging"
                className="block text-gray-700 font-bold mb-1 mr-2"
              >
                Verpakkingsmethode
              </label>
              <span className="text-gray-400 text-sm">
                *afmetingen zijn in cm (l x b x h)
              </span>
              <select
                value={selectedDoos ? JSON.stringify(selectedDoos) : ""}
                className="border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                disabled={loading || error}
                onChange={handleChange}
              >
                {selectedDoos && (
                  <option value={JSON.stringify(selectedDoos)}>
                    {selectedDoos.NAAM} ({selectedDoos.LENGTE} x{" "}
                    {selectedDoos.BREEDTE} x {selectedDoos.HOOGTE})* &euro;{" "}
                    {selectedDoos.PRIJS.toLocaleString("nl-BE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: true,
                      decimalSeparator: ",",
                      thousandSeparator: ".",
                    })}
                  </option>
                )}
                {!loading &&
                  dozen.map(
                    (doos) =>
                      doos.DOOSID !== (selectedDoos && selectedDoos.DOOSID) && (
                        <option key={doos.DOOSID} value={JSON.stringify(doos)}>
                          {doos.NAAM} ({doos.LENGTE} x {doos.BREEDTE} x{" "}
                          {doos.HOOGTE})* &euro;{" "}
                          {doos.PRIJS.toLocaleString("nl-BE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                            decimalSeparator: ",",
                            thousandSeparator: ".",
                          })}
                        </option>
                      )
                  )}
              </select>
            </div>
            <button
              type="submit"    
              className="mt-6 bg-dw-red text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline lg:text-center
          w-full lg:w-auto hover:contrast-200 transition duration-300"
            >
              Bestelling plaatsen
            </button>
          </form>
        </div>
        <div className="">
          <h2 className="uppercase text-lg font-light tracking-widest mb-2">
            Producten
          </h2>
          {cart.map((prod) => (
            <div
              key={prod.id}
              className="lg:col-span-4 border rounded p-4 shadow"
            >
              <div className="flex flex-col lg:flex-row lg:items-center justify-between">
                <h4 className="font-bold">{prod.name}</h4>
                <p className="">Aantal: {prod.qty}</p>
              </div>
              <div className="">
                <h3 className="md:text-lg font-medium text-left">
                  &euro;{" "}
                  {(
                    parseFloat(prod.price) * parseFloat(prod.qty)
                  )?.toLocaleString("nl-BE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    useGrouping: true,
                    decimalSeparator: ",",
                    thousandSeparator: ".",
                  })}
                </h3>
              </div>
            </div>
          ))}
        </div>

        <div className="md:col-span-2 ">
          <div className="border rounded p-4 shadow">
            <h3 className="font-semibold uppercase tracking-widest text-center lg:text-left mb-4">
              Overzicht
            </h3>

            <div className="">
              <div className="flex items-center justify-between">
                <span className="block text-gray-500">Producten excl. btw</span>
                <span>
                  &euro;{" "}
                  {(ORDERBEDRAG * 0.79)?.toLocaleString("nl-BE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    useGrouping: true,
                    decimalSeparator: ",",
                    thousandSeparator: ".",
                  })}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="block text-gray-500">
                  Verpakking excl. btw
                </span>
                <span>
                  &euro;{" "}
                  {(selectedDoos?.PRIJS * 0.79).toLocaleString("nl-BE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    useGrouping: true,
                    decimalSeparator: ",",
                    thousandSeparator: ".",
                  })}
                </span>
              </div>
              <div className="flex items-center justify-between mb-2">
                <span className="block text-gray-500">21% btw</span>
                <span>
                  &euro;{" "}
                  {((ORDERBEDRAG + selectedDoos?.PRIJS) * 0.21).toLocaleString(
                    "nl-BE",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: true,
                      decimalSeparator: ",",
                      thousandSeparator: ".",
                    }
                  )}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-500 font-bold">Totaal incl. btw</span>
              <span className="font-semibold">
                &euro;{" "}
                {(ORDERBEDRAG + selectedDoos?.PRIJS).toLocaleString("nl-BE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  useGrouping: true,
                  decimalSeparator: ",",
                  thousandSeparator: ".",
                })}
              </span>
            </div>
            <div className="flex items-center justify-between mt-2">
              <span className="text-gray-500 font-bold">Levertijd</span>
              <span>
                {cart
                  .map((item) => {
                    return item.delivery;
                  })
                  .reduce((a, b) => Math.max(a, b), 0)}{" "}
                {cart.length > 0 && cart[0].delivery === 1
                  ? "werkdag"
                  : "werkdagen"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderForm;
