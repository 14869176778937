import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import Loader from "../Loader";
import Error from "../Error";
import LoginButton from "./LoginButton";

export default function AuthLanding() {
  const { error, isAuthenticated, isLoading } = useAuth0();

  if (error) {
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="text-5xl mb-10">
        <h1>Login mislukt!</h1>
      </div>
      <div>
        <p>
          Sorry, we waren niet succesvol om u aan te melden. De error hieronder
          kan u mogelijks verder helpen.
        </p>
      </div>
      <div>
        <Error error={error} />
      </div>
      <div className="bg-dw-red py-2 px-5 rounded hover:contrast-200 transition duration-300">
        <LoginButton />
      </div>
    </div>;
  }
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="text-5xl mb-10">
          <h1>Aanmelden...</h1>
        </div>
        <div>
          <p>Even geduld terwijl we u aanmelden</p>
          < Loader />
        </div>
      </div>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen gap-4">
        <div className="text-5xl">
          <h1>Login vereist</h1>
        </div>
        <p className="">U moet aanmelden om deze pagina weer te kunnen geven</p>
        <div className="bg-dw-red py-2 px-5 rounded hover:contrast-200 transition duration-300">
          <LoginButton />
        </div>
      </div>
    );
  }
}
