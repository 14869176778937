import React, { useState, useEffect, useRef } from "react";
import { RiNotification3Fill } from "react-icons/ri";
import { useWebSocket } from '../Context/websocket';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import getBrusselsDatetime from "../api/BrusselTijd";

const NotificationItem = ({ notification, onRemove }) => {
  const handleRemoveClick = () => {
    onRemove(notification.id);
  };

  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const getTimeAgo = async () => {
      const now = await getBrusselsDatetime();
      const notificationtime = new Date(notification.date_time);
      const notificationTimeToSubstract = new Date(notificationtime.getTime() - 2 * 60 * 60 * 1000);
      const diff = Math.abs(now - notificationTimeToSubstract);
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));

      if (weeks > 0) {
        setTimeAgo(`${weeks} ${weeks > 1 ? "weken" : "week"} geleden`);
      } else if (days > 0) {
        setTimeAgo(`${days} dag${days > 1 ? "en" : ""} geleden`);
      } else if (hours > 0) {
        setTimeAgo(`${hours} ${hours > 1 ? "uren" : "uur"} geleden`);
      } else if (minutes > 0) {
        setTimeAgo(`${minutes} minute${minutes > 1 ? "n" : ""} geleden`);
      } else {
        setTimeAgo("Juist nu");
      }
    };

    getTimeAgo();
  }, [notification.date_time]);

  return (
    <li key={notification.id} className="py-2 pr-6 relative font-light">
      <span style={{ textTransform: "none" }}>
        <Link to={`/user/bestellingen/${notification.ORDERID}`}>	
          Bestelling met nummer {notification.ORDERID} is {notification.new_status}.<br />
        </Link>
        <div className="text-gray-400 text-sm">
          {timeAgo}
        </div>
      </span>
      <button
        className="ml-2 text-gray-500 absolute bottom-0 right-0 mb-1"
        onClick={handleRemoveClick}
      >
        X
      </button>
    </li>
  );
};

const NotificationCenter = () => {
  const { isAuthenticated } = useAuth0();
  const { notifications, removeNotification } = useWebSocket();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const displayedNotifications = notifications.slice(0, 4);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleNotificationCenterClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleRemoveNotification = (id) => {
    removeNotification(id);
  };

  const handleViewAllClick = () => {
    setIsOpen(false);
  };

  if (!isAuthenticated) {
    return (
      <div>
        <RiNotification3Fill size={20} />
      </div>
    );
  }
  

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={handleNotificationCenterClick}
        className="relative flex items-center justify-center space-x-1"
      >
        <RiNotification3Fill size={20} />
        {notifications.length > 0 && (
          <span className="rounded-full w-[17px] h-[17px] flex justify-center items-center align-baseline font-bold text-[12px] p-1 bg-[#ffffff] text-black ml-[1.20rem] absolute bottom-3 left-2">
            {notifications.length}
          </span>
        )}
      </button>
      <div
        className={`absolute left-1/2 transform -translate-x-1/2 mt-4 z-10 transition-opacity duration-300 ${
          isOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="arrow absolute w-4 h-4 bottom-full -mb-2 left-1/2 transform -translate-x-1/2 bg-white border-t-2 border-transparent rotate-45 "></div>
        <div className="p-4 bg-white border rounded md:w-[370px] w-[300px]  py-2 px-3 text-gray-700 leading-tight focus:shadow-outline border-t-0 relative ">
          <h2 className="uppercase text-lg font-light tracking-widest mb-2 border-b text-center">
            Notificaties
          </h2>
          {notifications.length === 0 ? (
            <p className="text-center text-black">
              <span className="text-gray-800 font-light" style={{ textTransform: "none" }}>
                Geen nieuwe notificaties.
              </span>
            </p>
          ) : (
            <>
              <ul className="notification-list divide-y divide-gray-200">
                {displayedNotifications.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    onRemove={handleRemoveNotification}
                  />
                ))}
              </ul>
              <Link to="/notificaties">
                <button
                  className="block mt-4 bg-dw-red px-3 py-2 rounded text-white text-sm w-full hover:contrast-200 transition duration-300"
                  onClick={handleViewAllClick}
                >
                  Alle notificaties
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationCenter;
