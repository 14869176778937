import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../Components/authentication/LoginButton";
import LogoutButton from "../Components/authentication/LogoutButton";
import Loader from "./Loader";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";

const User = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <Loader />
      </div>
    );
  }
  if (!isAuthenticated) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen gap-4">
        <div className="text-5xl">
          <h1>Login vereist</h1>
        </div>
        <p className="">U moet aanmelden om deze pagina weer te kunnen geven</p>
        <div className="bg-dw-red py-2 px-5 rounded hover:contrast-200 transition duration-300">
          <LoginButton />
        </div>
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      <div className="max-w-7xl py-12 px-3 lg:mx-auto min-h-screen">
        <h1 className="text-3xl font-light text-black uppercase mb-8 text-center tracking-widest lg:text-left">
          Account
        </h1>
        <div className="flex flex-col-reverse lg:flex-row gap-4">
          <div className="lg:w-1/6">
            <Link to="/user/bestellingen">
              <button className="w-full bg-dw-red text-white rounded px-4 py-2 mb-4 hover:contrast-200">
                Bestellingen
              </button>
            </Link>
            <LogoutButton />
          </div>
          <div className="flex justify-between mb-4 lg:w-5/6 border rounded shadow p-4">
            <div>
              <h2 className="text-3xl lg:text-6xl font-bold">Hallo {user.name},</h2>
              <p className="text-base lg:text-lg">Hier vind je al je relevante informatie</p>
            </div>
            <div className="flex-grow flex justify-end items-center"><AiOutlineUser size={80}/></div>
            
          </div>
        </div>
      </div>
    );
  }
};

export default User;
