import ProductList from "../Components/Products/ProductList";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const [query, setQuery] = useState("");
  const [value, setValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setQuery(value);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setQuery("");
    setValue("");
  };

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  function handleCategoryChange(category) {
    if (selectedCategory === category) {
      setSelectedCategory(null);
      setQuery(""); 
      setValue(""); 
    } else {
      setSelectedCategory(category);
      setQuery(""); 
      setValue(""); 
    }
  }
  

  return (
    <div className="min-h-screen">
      <div className="max-w-7xl py-12 px-3 lg:mx-auto">
        <h1 className="text-3xl font-light text-black uppercase mb-8 text-center tracking-widest lg:text-left">
          Producten
        </h1>
        <div className="lg:grid lg:grid-cols-5">
          <div className="col-span-1 lg:pr-8">
            <div className="mb-4">
              <h2 className="uppercase font-light tracking-widest border-b mb-2">
                Zoeken
              </h2>
              <form
                onSubmit={handleSubmit}
                className="flex items-center gap-1 mb-1 border rounded w-full"
              >
                <input
                  type="text"
                  placeholder="Zoeken..."
                  value={value}
                  onChange={handleInputChange}
                  className="py-1 px-2 w-full focus:outline-none"
                />
                <button type="submit">
                  <AiOutlineSearch size={20} />
                </button>
                <button
                  type="reset"
                  onClick={handleReset}
                  className="py-0.5 px-1 mr-1"
                >
                  X
                </button>
              </form>
            </div>
            <div className="hidden lg:block">
              <h2 className="uppercase font-light tracking-widest border-b mb-4">
                Categorie
              </h2>
              <div className="flex flex-col gap-1 accent-dw-red">
                <label className="">
                  <input
                    type="checkbox"
                    checked={selectedCategory === "laptops"}
                    onChange={() => handleCategoryChange("laptops")}
                    className="mr-2"
                  />
                  Laptops
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedCategory === "smartphones"}
                    onChange={() => handleCategoryChange("smartphones")}
                    className="mr-2"
                  />
                  Smartphones
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedCategory === "monitoren"}
                    onChange={() => handleCategoryChange("monitoren")}
                    className="mr-2"
                  />
                  Monitoren
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedCategory === "computermuizen"}
                    onChange={() => handleCategoryChange("computermuizen")}
                    className="mr-2"
                  />
                  Computermuizen
                </label>
              </div>
            </div>
          </div>
          <div className="col-span-4">
          <ProductList searchQuery={query} category={selectedCategory}/>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        pauseOnHover="false"
        transition={Zoom}
        style={{
          width: "100%",
          maxWidth: "500px",
          textAlign: "center",
          "media (minWidth: 768px)": {
            width: "500px",
          },
        }}
      />
    </div>
  );
};

export default Home;
