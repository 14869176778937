import { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { CartState } from "../../Context/Context";
import { Link } from "react-router-dom";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth0 } from '@auth0/auth0-react';

const Cart = () => {
  const BASE_URL = `${process.env.REACT_APP_BASE_URL}`; 
  // Check the value of the state
  const { isAuthenticated } = useAuth0();
  const {
    state: { cart },
    dispatch,
  } = CartState();
  const [total, setTotal] = useState();

  const handleChange = (event, productId) => {
    
    let inputVal = event.target.value;
    let stock;
    const updatedCart = cart.map((prod) => {
      if (prod.id === productId) {
        return { ...prod, qty: inputVal };
      }
      return prod;
    });
    console.log(updatedCart)
    const foundProduct = updatedCart.find((prod) => prod.id === productId);
    stock =   foundProduct.stock
      if((parseInt(inputVal) >= 1 && parseInt(inputVal) <= stock)|| inputVal === ""){
      dispatch({ type: "CHANGE_CART_QTY", payload: { ...foundProduct, qty: inputVal } });
      }
      else if(parseInt(inputVal) > stock){
        
        toast.error(
          `Maximaal ${stock} ${
            stock === 1 ? "stuk" : "stuks"
          } beschikbaar.`
        );
        dispatch({ type: "CHANGE_CART_QTY", payload: { ...foundProduct, qty: stock }});
      }
      else{
        toast.error("Ongeldig nummer!")
        dispatch({ type: "CHANGE_CART_QTY", payload: { ...updatedCart[0], qty: 1 }});
      }
  };
  
  

  const handleBlur = (event, productId) => {
    let inputVal = event.target.value;
    const updatedCart = cart.map((prod) => {
      if (prod.id === productId) {
        return { ...prod };
      }
      return prod;
    });
    if(inputVal!==""){
    dispatch({ type: "CHANGE_CART_QTY", payload: { ...updatedCart[0], qty: inputVal } });
    }
    else{
      dispatch({ type: "CHANGE_CART_QTY", payload: { ...updatedCart[0], qty: 1 } });
    }
  };
 

  useEffect(() => {
    setTotal(
      cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0)
    );
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  if (cart.length === 0) {
    return (
      <div className="max-w-7xl py-12 px-3 lg:mx-auto min-h-[calc(100vh-168px)]">
        <h1 className="text-3xl font-light text-black mb-8 uppercase text-center tracking-widest lg:text-left">
          Winkelwagen
        </h1>
        <h1 className="text-center mb-4 text-2xl">Uw winkelwagen is leeg</h1>
        <Link to="/">
          <div className="flex space-x-2 justify-center">
            <button
              type="button"
              className="bg-dw-red py-2 px-5 text-white rounded hover:contrast-200 transition duration-300"
            >
              <p>HOME</p>
            </button>
          </div>
        </Link>
      </div>
    );
  } else {
    return (
      <div className="max-w-7xl py-12 px-3 lg:mx-auto min-h-[calc(100vh-168px)]">
        <h1 className="text-3xl font-light text-black mb-8 uppercase text-center tracking-widest lg:text-left">
          Winkelwagen
        </h1>
        <div className="flex flex-col lg:flex-row gap-2">
          <div className="flex-col lg:w-4/6">
            {cart.map((prod) => (
              <div
                key={prod.id}
                className="flex flex-col w-full border rounded shadow p-4 mb-2"
              >
                <div className="flex flex-wrap items-center justify-between md:flex-nowrap">
                  <div className="flex items-center gap-2">
                    <img
                      src={`${BASE_URL}/images/${prod.img}`}
                      alt={prod.title}
                      className="h-24 w-24 md:h-32 md:w-32 object-contain"
                    />
                    <div>
                      <h4 className="font-bold mb-1">{prod.name}</h4>
                      <div className="flex items-center">
                        <button
                          type="button"
                          className="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out mr-4"
                          onClick={() =>
                            dispatch({
                              type: "REMOVE_FROM_CART",
                              payload: prod,
                            })
                          }
                        >
                          <AiFillDelete size={24} />
                        </button>
                        <button
                          onClick={() =>
                            prod.qty > 1 &&
                            dispatch({
                              type: "DECREASE_CART_QTY",
                              payload: prod,
                            })
                          }
                          className="bg-white hover:bg-gray-100 text-gray-400 font-semibold px-2 border border-gray-400 rounded"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          
                          max={prod.stock}
                          id={`quantity-input-${prod.id}`}
                          value={prod.qty}
                          onChange={(e) => handleChange(e, prod.id)}
                          onBlur={handleBlur}
                          className="inline w-12 text-center border-2 rounded mx-1"
                        />
                        <button
                          onClick={() => {
                            prod.qty < prod.stock && prod.stock !== 0
                              ? dispatch({
                                  type: "INCREASE_CART_QTY",
                                  payload: prod,
                                })
                              : toast.error(
                                  `Maximaal ${prod.stock} ${
                                    prod.stock === 1 ? "stuk" : "stuks"
                                  } beschikbaar.`
                                );
                          }}
                          className="bg-white text-dw-red hover:bg-red-100 font-semibold px-2 border border-dw-red rounded shadow"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <h3 className="md:text-lg font-medium w-full md:w-auto text-right">
                    &euro;{" "}
                    {(
                      parseFloat(prod.price) * parseFloat(prod.qty)
                    )?.toLocaleString("nl-BE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: true,
                      decimalSeparator: ",",
                      thousandSeparator: ".",
                    })}
                  </h3>
                </div>
              </div>
            ))}
          </div>

          <div className="flex lg:w-2/6 h-full sticky top-2">
            <div className="w-full">
              <div className="border rounded p-4 shadow">
                <h3 className="font-semibold uppercase tracking-widest text-center lg:text-left mb-4">
                  Overzicht
                </h3>
                <div className="">
                  <div className="flex items-center justify-between">
                    <span className="block text-gray-500">
                      Subtotaal excl. btw
                    </span>
                    <span>
                      &euro;{" "}
                      {(total * 0.79)?.toLocaleString("nl-BE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      })}
                    </span>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <span className="block text-gray-500">21% btw</span>
                    <span>
                      &euro;{" "}
                      {(total * 0.21)?.toLocaleString("nl-BE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      })}
                    </span>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-gray-500 font-bold">
                    Totaal incl. btw
                  </span>
                  <span className="font-semibold">
                    &euro;{" "}
                    {total?.toLocaleString("nl-BE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: true,
                      decimalSeparator: ",",
                      thousandSeparator: ".",
                    })}
                  </span>
                </div>
                <Link to="/Order">
                  <button
                    type="button"
                    disabled = {!isAuthenticated || cart.length === 0}
                    className="w-full bg-dw-red text-white rounded-md px-4 py-2 mt-4 hover:contrast-200 disabled:bg-gray-400 disabled:text-gray-200"
                  >
                    Verder met bestellen
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar
          pauseOnHover="false"
          transition={Zoom}
          style={{
            width: "100%",
            maxWidth: "500px",
            textAlign: "center",
            "media (minWidth: 768px)": {
              width: "500px",
            },
          }}
        />
      </div>
    );
  }
};

export default Cart;