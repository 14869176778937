import BestellingList from "../Components/Bestelling/BestellingList";
import { AiOutlineSearch } from "react-icons/ai";
import { useState } from "react";


export default function BestellingOverzicht() {
  const [query, setQuery] = useState("");
  const [value, setValue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setQuery(value);
  };

  const handleReset = (event) => {
    event.preventDefault();
    setQuery("");
    setValue("");
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const numbersRegex = /^$|^[0-9]+$/;
  
    if (numbersRegex.test(inputValue)) {
      setValue(inputValue);
    }
  };

  return (
    <div className="max-w-7xl py-12 px-3 mx-auto min-h-[calc(100vh-168px)]">
      <h1 className="text-3xl font-light text-black uppercase mb-8 text-center tracking-widest lg:text-left">
        Bestellingen
      </h1>
      <div className="lg:grid lg:grid-cols-5">
        <div className="col-span-1 lg:pr-8">
          <div className="mb-4">
            <h2 className="uppercase font-light tracking-widest border-b mb-2">
              Zoeken
            </h2>
            <form
              onSubmit={handleSubmit}
              className="flex items-center gap-1 mb-1 border rounded w-full"
            >
              <input
                type="text"
                placeholder="Zoeken..."
                value={value}
                onChange={handleInputChange}
                className="py-1 px-2 w-full focus:outline-none"
              />
              <button type="submit">
                <AiOutlineSearch size={20} />
              </button>
              <button
                type="reset"
                onClick={handleReset}
                className="py-0.5 px-1 mr-1"
              >
                X
              </button>
            </form>
          </div>
        </div>
        <div className="col-span-4 lg:pr-8">
          <BestellingList searchQuery={query} />
        </div>
      </div>
    </div>
  )
}
