import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Images/Delaware-logo.png";
import AuthenticationButton from "./authentication/AuthenticationButton";
import {
  AiOutlineShoppingCart,
  AiOutlineUser,
  AiOutlineMenu,
  AiOutlineClose,
} from "react-icons/ai";
import { CartState } from "../Context/Context";
import { useAuth0 } from "@auth0/auth0-react";
import NotificatieCentrum from './NotficatieCentrum'

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const {
    state: { cart },
  } = CartState();
  const handleNav = () => {
    setNav((prev) => !prev);
  };
  const { isAuthenticated } = useAuth0();
  let newTotalItems = 0;
  for (const item of cart) {
    newTotalItems += item.qty;
  }
  if (totalItems !== newTotalItems) {
    setTotalItems(newTotalItems);
  }

  return (
    <nav className="bg-dw-red text-white py-3 uppercase">
      <div className="flex items-center justify-between max-w-7xl mx-auto px-3">
        <Link to="/" className="text-lg font-bold">
          <img src={logo} alt="Delaware logo" className="h-10" />
        </Link>
        <div className="flex gap-1 items-center"></div>
        <ul className="hidden lg:flex flex-row gap-8">
          <li className="flex items-center gap-1">
            <Link to="/Trackandtrace">Track&Trace</Link>
          </li>
          <li>
          <NotificatieCentrum />
          </li>
          <li className="flex items-center gap-1">
            <span
              className={
                totalItems === 0
                  ? `hidden invisible`
                  : `rounded-full w-[17px] h-[17px] flex justify-center items-center align-baseline font-bold text-[12px] p-1 bg-[#ffffff] text-black absolute top-3 ml-[1.20rem]`
              }
            >
              {totalItems}
            </span>
            <Link to="/Cart">
              <AiOutlineShoppingCart size={20} />
            </Link>
          </li>

          <li className="flex items-center gap-1">
            {isAuthenticated ? null : <AiOutlineUser size={20} />}
            <AuthenticationButton />
          </li>
        </ul>
        <ul className="lg:hidden flex justify-between items-center gap-6">
          <li>
          <NotificatieCentrum />
          </li>
          <li className="flex items-center gap-1">
            <Link to="/Cart" className="flex items-center gap-5">
              <div
                className={
                  totalItems === 0
                    ? `hidden invisible`
                    : `rounded-full w-[17px] h-[17px] flex items-center align-baseline justify-center font-bold text-[15px] bg-[#ffffff] text-black absolute ml-[1.20rem] mb-5`
                }
              >
                {totalItems}
              </div>
              <AiOutlineShoppingCart size={30} />
            </Link>
          </li>
          <li className="flex items-center gap-1">
            {isAuthenticated ? null : <AiOutlineUser size={20} />}
            <AuthenticationButton />
          </li>
          <div onClick={handleNav} className="cursor-pointer">
            <AiOutlineMenu size={30} />
          </div>
        </ul>
        <div
          className={`fixed right-0 top-0 w-full h-full bg-dw-red z-10 ease-in-out duration-700 ${
            !nav ? "translate-x-full" : null
          }`}
        >
          <AiOutlineClose
            size={30}
            onClick={handleNav}
            className="cursor-pointer m-4 fixed right-0"
          />

          <ul className="flex flex-col h-screen gap-10 cursor-pointer items-center justify-center text-2xl">
            <li>
              <Link to="/" onClick={handleNav}>
                Home{" "}
              </Link>
            </li>

            <li className="flex items-center gap-1">
              <Link to="/Trackandtrace" onClick={handleNav}>Track&Trace</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
