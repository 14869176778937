import { useState, useEffect } from 'react';
import { useWebSocket } from '../Context/websocket';
import { Link } from "react-router-dom";
import getBrusselsDatetime from "../api/BrusselTijd";

const NotificationItem = ({ notification, onRemove }) => {
  const handleRemoveClick = () => {
    onRemove(notification.id);
  };

  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const getTimeAgo = async () => {
      const now = await getBrusselsDatetime();
      const notificationtime = new Date(notification.date_time);
      const notificationTimeToSubstract = new Date(notificationtime.getTime() - 2 * 60 * 60 * 1000);
      const diff = Math.abs(now - notificationTimeToSubstract);
      
      const minutes = Math.floor(diff / (1000 * 60));
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));

      if (weeks > 0) {
        setTimeAgo(`${weeks} ${weeks > 1 ? "weken" : "week"} geleden`);
      } else if (days > 0) {
        setTimeAgo(`${days} dag${days > 1 ? "en" : ""} geleden`);
      } else if (hours > 0) {
        setTimeAgo(`${hours} ${hours > 1 ? "uren" : "uur"} geleden`);
      } else if (minutes > 0) {
        setTimeAgo(`${minutes} minute${minutes > 1 ? "n" : ""} geleden`);
      } else {
        setTimeAgo("Juist nu");
      }
    };

    getTimeAgo();
  }, [notification.date_time]);

  return (
    <li key={notification.id} className="font-light lg:col-span-4 border rounded p-4 shadow flex justify-between">
      <Link to={`/user/bestellingen/${notification.ORDERID}`}>
        <div>
          Bestelling met nummer {notification.ORDERID} is {notification.new_status}.<br />
          <div className="text-gray-400 text-sm">
            {timeAgo}
          </div>
        </div>
      </Link>
      <div>
        <button
          className="text-gray-500"
          onClick={handleRemoveClick}
        >
          X
        </button>
      </div>
    </li>
  );
};

function Notificatie() {
  let { notifications, removeNotification } = useWebSocket();

  const handleRemoveNotification = (id) => {
    removeNotification(id);
  };

  return (
    <div className="min-h-screen max-w-7xl py-12 px-3 lg:mx-auto">
      <h1 className="text-3xl font-light text-black uppercase mb-8 text-center tracking-widest lg:text-left">
        Notificaties
      </h1>
      {notifications.length === 0 ? (
        <p className="text-center text-black">
          <span className="text-gray-800 font-light" style={{ textTransform: "none" }}>
            Geen nieuwe notificaties.
          </span>
        </p>
      ) : (
        <>
          <ul className="flex flex-col gap-2">
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onRemove={handleRemoveNotification}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default Notificatie;
