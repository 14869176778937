import Product from "./Product";
import useProducts  from "../../api/products"
import { useState, useEffect } from "react";
import Error from "../Error";
import Loader from "../Loader";

function ProductList({ searchQuery, category }) {
  const { getAll, getByCategory, getBySearch} = useProducts();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        if (category === null) {
          setProducts(await getAll());
        } else {
          setProducts(await getByCategory(category));
        }
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [category]);

  useEffect(() => {
    const fetchFilteredProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        if (searchQuery !== "") {
          setFilteredProducts(await getBySearch(searchQuery));
        } else {
          setFilteredProducts([]);
        }
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilteredProducts();
  }, [category, searchQuery]);

  const productList = searchQuery === "" ? products : filteredProducts;

  return (
    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <Error error={error} />
      <Loader loading={loading} />
      {productList.length === 0 && !error && !loading && (
        <div className="col-span-3">
          <h3 className="font-bold text-lg mb-2">Geen zoekresultaat</h3>
          <p>
            Helaas! Wij vonden geen producten voor de zoekopdracht "{searchQuery}".
          </p>
        </div>
      )}
      {productList.map((p) => (
        <Product
          name={p.NAAM}
          price={p.PRIJS}
          img={p.FOTO}
          description={p.OMSCHRIJVING}
          stock={p.AANTAL}
          key={p.PRODUCTID}
          id={p.PRODUCTID}
          delivery={p.LEVERTIJD}
        />
      ))}
    </ul>
  );
}

export default ProductList;
