import React from "react";
import { BsCartCheck } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function OrderSuccess() {
  const location = useLocation();
  const orderId = location.state && location.state.orderId;
  return (
    <div className="min-h-screen  max-w-7xl py-12 px-3 lg:mx-auto">
      <div className="flex items-center justify-center mb-8">
        <BsCartCheck size={200} color={"#ec4842"} className="text-center" />
      </div>
      <div className="text-center mb-8">
        <h2 className="uppercase text-2xl font-light tracking-widest mb-2">
          Uw bestelling werd succesvol geplaatst!
        </h2>
        <p>Uw bestelnummer is: </p>
        <Link to={`/user/bestellingen/${orderId}`}>
          <span className="text-3xl text-center hover:opacity-50 underline transition duration-300">{orderId}</span>
        </Link>
      </div>
<p className="text-center mb-1">Klik op uw bestelnummer om het overzicht van uw bestelling te bekijken, of ga verder naar</p>
      <Link to="/">
        <div className="flex space-x-2 justify-center">
          <button
            type="button"
            className="bg-dw-red py-2 px-5 text-white rounded hover:contrast-200 transition duration-300"
          >
            <p>HOME</p>
          </button>
        </div>
      </Link>
    </div>
  );
}

export default OrderSuccess;
