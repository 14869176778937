export const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const existingItemIndex = state.cart.findIndex((item) => item.id === action.payload.id);
      if (existingItemIndex !== -1) {
        const updatedCart = [...state.cart];
        if( updatedCart[existingItemIndex].qty + action.payload.qty <= action.payload.stock && action.payload.stock !==0) {
        updatedCart[existingItemIndex].qty += action.payload.qty;
        return { ...state, cart: updatedCart };
        }
        else{
          console.log(updatedCart[existingItemIndex].qty, action.payload.qty)
          return { ...state, cart: updatedCart };
        }
      } else {
        return { ...state, cart: [...state.cart, { ...action.payload, qty: action.payload.qty }] };
      }  
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((c) => c.id !== action.payload.id),
      };
      case "INCREASE_CART_QTY":
        return {
          ...state,
          cart: state.cart.map((c) =>
            c.id === action.payload.id ? { ...c, qty: c.qty + 1 } : c
          ),
        };
        case "DECREASE_CART_QTY":
        return {
          ...state,
          cart: state.cart.map((c) =>
            c.id === action.payload.id ? { ...c, qty: c.qty - 1 } : c
          ),
        };
        case "CHANGE_CART_QTY":
          if(action.payload.qty !== ""){
        return {
        ...state,
        cart: state.cart.map((c) =>
        c.id === action.payload.id ? { ...c, qty: parseInt(action.payload.qty)} : c
        ),
        };
         }
         else{return {
          ...state,
          cart: state.cart.map((c) =>
          c.id === action.payload.id ? { ...c, qty: null} : c
          ),
          };

         }
    
        case "CLEAR_CART":
          localStorage.removeItem('cart');
        return {
         ...state,
         cart: []
        };
      
    default:
      return state;
  }
};

export const productReducer = (state, action) => {
  switch (action.type) {
    case "SORT_BY_PRICE":
      return { ...state, sort: action.payload };
    case "FILTER_BY_STOCK":
      return { ...state, byStock: !state.byStock };
    case "FILTER_BY_DELIVERY":
      return { ...state, byFastDelivery: !state.byFastDelivery };
    case "FILTER_BY_RATING":
      return { ...state, byRating: action.payload };
    case "FILTER_BY_SEARCH":
      return { ...state, searchQuery: action.payload };
    case "CLEAR_FILTERS":
      return { byStock: false, byFastDelivery: false, byRating: 0 };
    default:
      return state;
  }
};