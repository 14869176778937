import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { CartState } from "../Context/Context";
import Error from "../Components/Error";
import Loader from "../Components/Loader";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useProducts from "../api/products";

function ProductDetails() {
  const BASE_URL = process.env.REACT_APP_BASE_URL; 
  console.log(BASE_URL);
  const { getById } = useProducts();
  const [product, setProduct] = useState();
  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    state: { cart },
    dispatch,
  } = CartState();
  let { id } = useParams();

  const handleChange = (event) => {
    const inputVal = event.target.value;
    if (parseInt(inputVal) > 0 || inputVal === "") {
    setQuantity(parseInt(inputVal));
  }};

  const handleBlur = (event) => {
    if (event.target.value === "") {
      setQuantity(1);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        const {
          NAAM,
          PRIJS,
          FOTO,
          OMSCHRIJVING,
          AANTAL,
          LEVERTIJD,
          PRODUCTID,
        } = await getById(id);
        const data = {
          name: NAAM,
          price: PRIJS,
          img: FOTO,
          description: OMSCHRIJVING,
          stock: AANTAL,
          delivery: LEVERTIJD,
          id: PRODUCTID,
        };
        setProduct(data);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [id]);

  if (error) {
    return (
      <div className="min-h-screen max-w-7xl py-12 px-3 lg:mx-auto">
        <Loader loading={loading} />
        <Error error={error} />
      </div>
    );
  }

  return (
    <div className="min-h-screen max-w-7xl py-12 px-3 lg:mx-auto">
      <div className="lg:grid lg:grid-cols-2 gap-8">
        <Loader loading={loading} />
        <Error error={error} />
        <img
          src={`${BASE_URL}/images/${product?.img}`}
          alt={product?.name}
          className="border rounded mb-4 lg:mb-0"
        />
        <div>
          <h1 className="font-thin text-3xl md:text-5xl uppercase mb-4">
            {product?.name}
          </h1>
          <div className="mb-4">
            <h3 className="text-3xl md:text-4xl font-bold mb-1 inline">
              &euro;{" "}
              {product?.price.toLocaleString("nl-BE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
                decimalSeparator: ",",
                thousandSeparator: ".",
              })}
            </h3>
            <span className="ml-2">Incl. btw</span>
            <div className="mb-2">
            <h4 className="text-xl inline">
              &euro;{" "}
              {(product?.price * 0.79).toLocaleString("nl-BE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
                decimalSeparator: ",",
                thousandSeparator: ".",
              })}
            </h4>
            <span className="ml-2 text-sm">Excl. btw</span>
          </div>
          </div>
          

          <p className="mb-4">{product?.description}</p>
          <div className="md:flex justify-between items-center gap-32 mb-4">
            <div className="flex items-center mb-4 md:mb-0">
              <button
                onClick={() =>
                  setQuantity((prev) => (prev === 1 ? 1 : prev - 1))
                }
                className="bg-white hover:bg-gray-100 text-gray-400 font-semibold px-6 py-3 border-2 border-gray-400 rounded"
              >
                -
              </button>
              <input
                type="number"
                min="0"
                max={product?.stock}
                maxLength="3"
                id="quantity-input"
                value={quantity || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                className="inline w-20 text-center border-2 rounded mx-1 py-3 text-lg"
              />
              <button
                onClick={() =>
                  quantity < product.stock
                    ? setQuantity((prev) => prev + 1)
                    : toast.error("Te weinig stock!")
                }
                className="bg-white text-dw-red hover:bg-red-100 font-semibold  px-6 py-3 border-2 border-dw-red rounded shadow"
              >
                +
              </button>
            </div>
            <button
              className="flex items-center justify-center bg-dw-red py-2 px-6 w-full rounded hover:contrast-200 transition duration-300"
              onClick={() => {
                const productcart = cart.find((prod) => product.id === prod.id);
                if (productcart) {
                  console.log(productcart);
                  if (
                    product.stock !== 0 &&
                    product.stock - (productcart.qty + quantity) >= 0
                  ) {
                    //met find werken constante van die find maken van lijn 69
                    dispatch({
                      type: "ADD_TO_CART",
                      payload: { ...product, qty: quantity },
                    });
                    toast.success(`${product.name} (${quantity}) toegevoegd! `);
                  } else {
                    toast.error("Te weinig Stock!");
                  }
                } else if (
                  product.stock !== 0 &&
                  product.stock - quantity >= 0
                ) {
                  dispatch({
                    type: "ADD_TO_CART",
                    payload: { ...product, qty: quantity },
                  });
                  toast.success(`${product.name} (${quantity}) toegevoegd! `);
                } else {
                  toast.error("Te weinig Stock!");
                }
              }}
            >
              <AiOutlineShoppingCart color="white" size={34} className="" />
            </button>
          </div>
          <div className="text-gray-400">
            <p>Stock: {product?.stock}</p>
            <p>
              Levertijd: {product?.delivery}{" "}
              {product?.delivery === 1 ? "werkdag" : "werkdagen"}
            </p>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        pauseOnHover="false"
        transition={Zoom}
        style={{
          width: "100%",
          maxWidth: "500px",
          textAlign: "center",
          "media (minWidth: 768px)": {
            width: "500px",
          },
        }}
      />
    </div>
  );
}

export default ProductDetails;
