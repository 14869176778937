const getBrusselsDatetime = async () => {
  try {
    const response = await fetch('https://worldtimeapi.org/api/timezone/Europe/Brussels');
    if (response.ok) {
      const data = await response.json();
      const brusselsDatetime = new Date(data.datetime);
      return brusselsDatetime;
      
    } else {
      console.error('Failed to fetch Brussels datetime');
    }
  } catch (error) {
    console.error('Error fetching Brussels datetime:', error);
  }
};


export default getBrusselsDatetime;