import { useState, useEffect } from "react";
import Bestelling from "./Bestelling";
import Error from "../Error";
import Loader from "../Loader";
import useBestellingen from "../../api/bestelling";
import { Link } from "react-router-dom";

function BestellingList({ searchQuery }) {
  const [bestellingen, setBestellingen] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getAll, getBySearch } = useBestellingen();
  const [filteredBestellingen, setFilteredBestellingen] = useState([]);

  useEffect(() => {
    const fetchBestellingen = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await getAll();
        setBestellingen(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBestellingen();
  }, [getAll]);

  useEffect(() => {
    const fetchFilteredBestellingen = async () => {
      try {
        setLoading(true);
        setError(null);
        if (searchQuery !== "") {
          setFilteredBestellingen(await getBySearch(searchQuery));
        } else {
          setFilteredBestellingen([]);
        }
      } catch (error) {
        console.log(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilteredBestellingen();
  }, [searchQuery]);

  const bestellingenList = searchQuery === "" ? bestellingen : filteredBestellingen;

  const parseDate = (dateString) => {
    const parts = dateString.split(/[ /:-]/);
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  };

  return (
    <ul className="grid grid-cols-1 gap-8">
      <Error error={error} />
      <Loader loading={loading} />
      {bestellingenList.length > 0 ? (
        bestellingenList
          .sort((a, b) => {
            const dateComparison = parseDate(b.ORDERDATUM) - parseDate(a.ORDERDATUM);

            if (dateComparison !== 0) {
              return dateComparison;
            }

            return b.ORDERID - a.ORDERID;
          })
          .map((b) => (
            <Bestelling status={b.STATUS} orderdatum={b.ORDERDATUM} key={b.ORDERID} id={b.ORDERID} />
          ))
      ) : (
        <>
          {searchQuery === "" ? (
            <div className="col-span-3">
              <h1 className="text-center mb-4 text-2xl">U heeft geen bestellingen</h1>
              <Link to="/">
                <div className="flex space-x-2 justify-center">
                  <button
                    type="button"
                    className="bg-dw-red py-2 px-5 text-white rounded hover:contrast-200 transition duration-300"
                  >
                    <p>HOME</p>
                  </button>
                </div>
              </Link>
            </div>
          ) : (
            <div className="col-span-3">
              <h3 className="font-bold text-lg mb-2">Geen zoekresultaat</h3>
              <p>Helaas! Wij vonden geen bestelling met nummer {searchQuery}.</p>
            </div>
          )}
        </>
      )}
    </ul>
  );
}

export default BestellingList;
