import { useAuth0 } from '@auth0/auth0-react';

function LogoutButton() {
  const { logout } = useAuth0();
  return (
    <div className="grid">
    <button
      type="button"
      className="bg-dw-red py-2 px-5 rounded hover:contrast-200 transition duration-300 text-white"
      onClick={() => logout({
        returnTo: window.location.origin,
      })}
    >
    <p>Afmelden</p>
    </button>
    </div>
  );
}

export default LogoutButton;
