import {VscError} from 'react-icons/vsc';

export default function Error({ error }) {
  if (error) {
    return (
      <div className="border flex justify-center items-center bg-dw-red rounded text-white py-4" data-cy="transactions_error">
        <div className='flex flex-col justify-center items-center gap-1'>
        <div className='flex items-center gap-1'>
          <VscError size={20} className=""/>
          <h4 className="flex items-center">Foutmelding: {error.message || JSON.stringify(error)}</h4>
        </div>
        <p className=''>Probeer later opnieuw...</p>
        </div>
      </div>
    );
  }

  return null;
}