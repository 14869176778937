import axios from 'axios';

const baseUrl = `https://api.basisregisters.vlaanderen.be/v1/postinfo`;

export const getGemeente = async (postcode) => {
    const {
      data
    } = await axios.get(`${baseUrl}/${postcode}`); 
  
    return data.postnamen[0].geografischeNaam.spelling; 
  };