import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import * as bestellingenApi from "../api/bestelling";s
import useBestellingen from "../api/bestelling";
import * as dozenApi from "../api/dozen";
import Error from "../Components/Error";
import Loader from "../Components/Loader";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as gemeenteApi from "../api/gemeente";

function BestellingDetails(props) {
  const { update, getById } = useBestellingen();
  const [bestelling, setBestelling] = useState();
  const [producten, setProducten] = useState();
  let { id } = useParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dozen, setDozen] = useState();
  const [selectedDoos, setSelectedDoos] = useState();
  const [totalePrijs, setTotalePrijs] = useState();
  const [straat, setStraat] = useState("");
  const [huisnummer, setHuisnummer] = useState("");
  const [postcode, setPostcode] = useState("");
  const [gemeente, setGemeente] = useState("");
  const [land, setLand] = useState("");
  const [countries, setCountries] = useState([]);

  const handleChange = (event) => {
    const selectedDoosObject = JSON.parse(event.target.value);
    setSelectedDoos(selectedDoosObject);
     const newTotalPrice =
       bestelling.ORDERBEDRAG + selectedDoosObject.PRIJS;
       console.log(newTotalPrice);
     setTotalePrijs(newTotalPrice);
  };

  const handleSubmit = async () => {
    try {
      if (straat.length < 2 || straat.length > 255) {
        alert("Geef een geldige straat door tussen de 2 en 255 letters");
        return;
      } else if (huisnummer.length < 1 || huisnummer.length > 5) {
        alert("Geef een geldig huisnummer op tussen de 1-5 cijfers");
        return;
      } else if (!postcode.match(/^\d{2,5}$/)) {
        alert("Geef een geldige postcode in tussen de 2-5 cijfers");
        return;
      } else if (gemeente.length < 2 || gemeente.length > 255) {
        alert("Geef een geldige gemeente in tussen de 2-255 cijfers");
        return;
      } else {
        const adres =
          straat +
          " " +
          huisnummer +
          " " +
          postcode +
          " " +
          gemeente +
          " " +
          land;
        const updatedBestelling = {
          id: bestelling.ORDERID,
          LEVERADRES: adres,
          DOOS_DOOSID: selectedDoos.DOOSID,
          ORDERBEDRAG: totalePrijs,
        };

        await update(updatedBestelling);
        toast.success("Bestelling succesvol gewijzigd");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchBestellingEnDozen = async () => {
      try {
        setLoading(true);
        setError(null);
        let data = null;
        if (id) {
          data = await getById(id);
        } else {
          data = props.param;
        }
        setBestelling(data.bestelling[0]);
        setProducten(data.producten);
        const dataDozen = await dozenApi.getAll();
        setDozen(dataDozen);
        const adres = data.bestelling[0].LEVERADRES.split(" ");
        setStraat(adres[0]);
        setHuisnummer(adres[1]);
        setPostcode(adres[2]);
        setGemeente(adres[3]);
        setLand(adres[4]);
        setTotalePrijs(data.bestelling[0].ORDERBEDRAG - data.bestelling[0].doos.prijs);
        setSelectedDoos(data.bestelling[0].doos);
        setCountries(["België", "Nederland"]);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBestellingEnDozen();
  }, [id]);

  useEffect(() => {
    const fetchGemeente = async () => {
      try {
        setLoading(true);
        setError(null);
        const dataGemeente = await gemeenteApi.getGemeente(postcode);
        setGemeente(dataGemeente);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (postcode.length === 4) {
      fetchGemeente();
    }
  }, [postcode]);

  if (error) {
    return (
      <div className="min-h-screen max-w-7xl py-12 px-3 lg:mx-auto">
        <Loader loading={loading} />
        <Error error={error} />
      </div>
    );
  }
  return (
    <div className="min-h-screen max-w-7xl py-12 px-3 lg:mx-auto">
      <Loader loading={loading} />
      <Error error={error} />
      {props.param ? null : (
        <div className="hidden md:block">
          <Link
            to={`${window.location.href.substring(
              0,
              window.location.href.lastIndexOf("/")
            )}`}
          >
            <div className="text-lg text-black hover:opacity-50 transition duration-300 mb-8">
              {"<"} Alle bestellingen
            </div>
          </Link>
        </div>
      )}
      <h1 className="text-3xl font-light text-black uppercase text-center tracking-widest lg:text-left mb-8">
        Bestelling {bestelling?.ORDERID}
      </h1>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-4 lg:grid-cols-2 lg:gap-6">
        <div>
          <h2 className="uppercase text-lg font-light tracking-widest mb-2">
            Gegevens
          </h2>
          <div className="">
            <p className="mb-4">Besteld op {bestelling?.ORDERDATUM}</p>
            <div className="mb-4">
              {bestelling?.STATUS === "geplaatst" && !loading ? (
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 sm:gap-4 lg:grid-cols-1 lg:gap-6">
                  <div className="">
                    <label
                      htmlFor="street"
                      className="block text-gray-700 font-bold mb-1"
                    >
                      Straat<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="street"
                      name="street"
                      onChange={(e) => setStraat(e.target.value)}
                      value={straat}
                      placeholder="Straat"
                      required
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="number"
                      className="block text-gray-700 font-bold mb-1"
                    >
                      Nummer<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="number"
                      name="number"
                      value={huisnummer}
                      onChange={(e) => setHuisnummer(e.target.value)}
                      placeholder="Nummer"
                      required
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="postcode"
                      className="block text-gray-700 font-bold mb-1"
                    >
                      Postcode<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="postcode"
                      name="postcode"
                      value={postcode}
                      onChange={(e) => setPostcode(e.target.value)}
                      placeholder="Postcode"
                      required
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="gemente"
                      className="block text-gray-700 font-bold mb-1"
                    >
                      Gemeente<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="gemeente"
                      name="gemeente"
                      onChange={(e) => setGemeente(e.target.value)}
                      value={gemeente}
                      placeholder="Gemeente"
                      required
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="country"
                      className="block text-gray-700 font-bold mb-1"
                    >
                      Land<span className="text-red-500">*</span>
                    </label>
                    <select
                      id="country"
                      name="country"
                      placeholder="Land"
                      value={land}
                      onChange={(e) => setLand(e.target.value)}
                      required
                      className="border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="" disabled defaultValue hidden>
                        Selecteer een land
                      </option>
                      {countries.map((country) => (
                        <option
                          key={country}
                          value={country}
                        >
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-1 sm:gap-4 lg:grid-cols-1 lg:gap-6">
                  <div className="">
                    <label
                      htmlFor="street"
                      className="block text-gray-700 font-bold mb-1"
                    >
                      Straat
                    </label>
                    <div>{straat}</div>
                  </div>
                  <div className="">
                    <label
                      htmlFor="number"
                      className="block text-gray-700 font-bold mb-1"
                    >
                      Nummer
                    </label>
                    <div>{huisnummer}</div>
                  </div>
                  <div className="">
                    <label
                      htmlFor="postcode"
                      className="block text-gray-700 font-bold mb-1"
                    >
                      Postcode
                    </label>
                    <div>{postcode}</div>
                  </div>
                  <div className="">
                    <label
                      htmlFor="country"
                      className="block text-gray-700 font-bold mb-1"
                    >
                      Land
                    </label>
                    <div>{land}</div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-6">
            <h2 className="uppercase text-lg font-light tracking-widest mb-2">
              Verpakking
            </h2>
            <label
              htmlFor="packaging"
              className="block text-gray-700 font-bold mb-1 mr-2"
            >
              Verpakkingsmethode
            </label>
            <span className="text-gray-400 text-sm">
              *afmetingen zijn in cm (l x b x h)
            </span>
            {bestelling?.STATUS === "geplaatst" ? (
              <select
                value={selectedDoos ? JSON.stringify(selectedDoos) : ""}
                className="border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                disabled={loading || error}
                onChange={handleChange}
              >
                {!selectedDoos && <option value="">Select an option</option>}
                {selectedDoos && (
                  <option value={JSON.stringify(selectedDoos)}>
                    {selectedDoos.NAAM || selectedDoos.naam} (
                    {selectedDoos.LENGTE || selectedDoos.lengte} x{" "}
                    {selectedDoos.BREEDTE || selectedDoos.breedte} x{" "}
                    {selectedDoos.HOOGTE || selectedDoos.hoogte}) &euro;{" "}
                    {selectedDoos.PRIJS &&
                      selectedDoos.PRIJS.toLocaleString("nl-BE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      })}
                    {!selectedDoos.PRIJS &&
                      selectedDoos.prijs &&
                      selectedDoos.prijs.toLocaleString("nl-BE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      })}
                  </option>
                )}

                {!loading &&
                  dozen
                    .filter((doos) => doos.DOOSID !== selectedDoos.id)
                    .map(
                      (doos) =>
                        doos.DOOSID !==
                          (selectedDoos && selectedDoos.DOOSID) && (
                          <option
                            key={doos.DOOSID}
                            value={JSON.stringify(doos)}
                          >
                            {doos.NAAM} ({doos.LENGTE} x {doos.BREEDTE} x{" "}
                            {doos.HOOGTE}) &euro;{" "}
                            {doos.PRIJS.toLocaleString("nl-BE", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                              useGrouping: true,
                              decimalSeparator: ",",
                              thousandSeparator: ".",
                            })}
                          </option>
                        )
                    )}
              </select>
            ) : (
              <input
                type="text"
                id="doos"
                name="doos"
                value={`${bestelling?.doos.naam} (${
                  bestelling?.doos.lengte
                } x ${bestelling?.doos.breedte} x ${
                  bestelling?.doos.hoogte
                }) \u20AC ${bestelling?.doos.prijs.toLocaleString("nl-BE", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  useGrouping: true,
                  decimalSeparator: ",",
                  thousandSeparator: ".",
                })}`}
                placeholder="Doos"
                required
                readOnly
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline"
              />
            )}
          </div>
          {bestelling?.STATUS === "geplaatst" && (
            <button
              type="button"
              onClick={handleSubmit}
              disabled={!selectedDoos}
              className="mt-6 bg-dw-red text-white font-bold py-2 px-4 rounded hover:contrast-200 transition duration-300 lg:text-center
            w-full lg:w-auto"
            >
              Update Bestelling
            </button>
          )}
        </div>
        <div className="">
          <h2 className="uppercase text-lg font-light tracking-widest mb-2">
            Producten
          </h2>
          {!loading ? (
            producten.map((product) => (
              <div
                key={product.product_id}
                className="lg:col-span-4 border rounded p-4 shadow"
              >

                <div className="flex flex-col lg:flex-row lg:items-center justify-between">
                  <h4 className="font-bold">{product.PRODUCTNAAM}</h4>
                  <p className="">Aantal: {product.quantity}</p>
                </div>
                <div className="">
                  <h3 className="md:text-lg font-medium text-left">
                    &euro;{" "}
                    {(
                      parseFloat(product.EENHEIDSPRIJS) *
                      parseFloat(product.quantity)
                    )?.toLocaleString("nl-BE", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: true,
                      decimalSeparator: ",",
                      thousandSeparator: ".",
                    })}
                  </h3>
                </div>
              </div>
            ))
          ) : (
            <div colSpan="3" className="px-4 py-2 text-center">
              <h1> Producten laden...</h1>
            </div>
          )}
        </div>
        <div className="md:col-span-2 ">
          <div className="border rounded p-4 shadow">
            <h3 className="font-semibold uppercase tracking-widest text-center lg:text-left mb-4">
              Overzicht
            </h3>

            <div className="">
              <div className="flex items-center justify-between">
                <span className="block text-gray-500">Producten excl. btw</span>
                <span>
                  &euro;{" "}
                  {(totalePrijs * 0.79)?.toLocaleString("nl-BE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    useGrouping: true,
                    decimalSeparator: ",",
                    thousandSeparator: ".",
                  })}
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="block text-gray-500">
                  Verpakking excl. btw
                </span>
                <span>
                  &euro;{" "}
                  {selectedDoos && selectedDoos.prijs
                    ? (selectedDoos?.prijs * 0.79).toLocaleString("nl-BE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      })
                    : (selectedDoos?.PRIJS * 0.79).toLocaleString("nl-BE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      })}
                </span>
              </div>
              <div className="flex items-center justify-between mb-2">
                <span className="block text-gray-500">21% btw</span>
                <span>
                  &euro;{" "}
                  {selectedDoos && selectedDoos.prijs
                    ? (
                        (totalePrijs + selectedDoos?.prijs) *
                        0.21
                      ).toLocaleString("nl-BE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      })
                    : (
                        (totalePrijs + selectedDoos?.PRIJS) *
                        0.21
                      ).toLocaleString("nl-BE", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      })}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-gray-500 font-bold mb-2">
                Totaal incl. btw
              </span>
              <span className="font-semibold">
                &euro;{" "}
                {selectedDoos && selectedDoos.prijs
                  ? (totalePrijs + selectedDoos?.prijs).toLocaleString(
                      "nl-BE",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      }
                    )
                  : (totalePrijs + selectedDoos?.PRIJS).toLocaleString(
                      "nl-BE",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                        decimalSeparator: ",",
                        thousandSeparator: ".",
                      }
                    )}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-gray-500 font-medium">Status:</p>
              <p
                style={{
                  color:
                    bestelling?.STATUS === "geplaatst"
                      ? "orange"
                      : bestelling?.STATUS === "geleverd"
                      ? "green"
                      : "red",
                }}
              >
                {bestelling?.STATUS}
              </p>
            </div>
            <div className="flex items-center justify-between mb-2">
              <p className="text-gray-500 font-medium">Track and Trace Code:</p>{" "}
              <p>
                {bestelling?.TRACKANDTRACECODE
                  ? bestelling.TRACKANDTRACECODE
                  : "In verwerking"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        pauseOnHover="false"
        transition={Zoom}
        style={{
          width: "100%",
          maxWidth: "500px",
          textAlign: "center",
          "media (minWidth: 768px)": {
            width: "500px",
          },
        }}
      />
    </div>
  );
}

export default BestellingDetails;
