import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';

export default function AuthenticationButton() {
  const {
    isAuthenticated,
    user,
  } = useAuth0(); 

  if (isAuthenticated) { 
    const { name, picture, givenName } = user;
    let nameArray = name.split(" ");
    return (
      <div onClick={() => window.location.href='/user'} className="flex flex-row items-center gap-1 cursor-pointer">
        <div className="col w-[25px] lg:w-[20px]">
          <img src={picture} size={10} alt={givenName} className="rounded" />
        </div>
        <div className="hidden lg:block">
          {nameArray[0]}
        </div>
      </div>
    );
  }

  return <LoginButton />;
}
