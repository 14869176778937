import React, { useEffect, useState, createContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const WebSocketContext = createContext();

const WebSocketProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const WEBSOCKET_URL = `${process.env.REACT_APP_WEBSOCKET_URL}`;

  const establishConnection = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const newSocket = new WebSocket(WEBSOCKET_URL);

      newSocket.addEventListener("open", () => {
        console.log("WebSocket connection established");
        setSocket(newSocket);
        const message = JSON.stringify({ token: accessToken });
        newSocket.send(message);
      });

      newSocket.addEventListener("message", (event) => {
        console.log("WebSocket message received:", event.data);
        const parsedData = JSON.parse(event.data);
        const { notificaties } = parsedData;
        setMessages(notificaties);
      });

      newSocket.addEventListener("close", () => {
        console.log("WebSocket connection closed");
        setSocket(null);
      });
    } catch (error) {
      console.error("Error establishing WebSocket connection:", error);
    }
  };

  const removeNotification = async (id) => {
    if (socket) {
      const token1 = await getAccessTokenSilently();
      const message = JSON.stringify({ action: "remove", id, token: token1 });
      socket.send(message);
    }
  };

  const closeConnection = () => {
    if (socket) {
      socket.close();
      setSocket(null);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      establishConnection();
    }

    return () => {
      closeConnection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <WebSocketContext.Provider
      value={{
        notifications : messages,
        removeNotification,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => React.useContext(WebSocketContext);

export default WebSocketProvider;
