import "./App.css";
import {Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Cart from "./Components/Cart/Cart";
import AuthLanding from "./Components/authentication/AuthLanding";
import User from "./Components/User"
import NotFound from "./Pages/NotFound";
import ProductDetails from "./Pages/ProductDetails";
import BestellingOverzicht from "./Pages/BestellingOverzicht";
import BestellingDetails from "./Pages/BestellingDetails";
import TrackAndTrace from './Pages/TrackAndTrace';
import RequireAuth from './Components/authentication/RequireAuth';
import Order from "./Pages/Order";
import OrderSuccess from "./Pages/OrderSuccess";
import Notificatie from "./Pages/Notificatie";



function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/:id/:title" element={<ProductDetails/>}/>
        <Route path="/Cart" element={<Cart />} />
        <Route path="/login" element={<AuthLanding />} />
        <Route path="/user" element={<RequireAuth><User /></RequireAuth>} />
        <Route path="/user/bestellingen" element={<RequireAuth><BestellingOverzicht /></RequireAuth>} />
        <Route path="/user/bestellingen/:id" element={<RequireAuth><BestellingDetails /></RequireAuth>} />
        <Route path="/Trackandtrace" element={<TrackAndTrace />}/>
        <Route path="/order" element={<RequireAuth><Order /></RequireAuth>} />
        <Route path="/order/success" element={<RequireAuth><OrderSuccess /></RequireAuth>} />
        <Route path="/order/:id" element={<RequireAuth><Order /></RequireAuth>} />
        <Route path="notificaties" element={<RequireAuth><Notificatie /></RequireAuth>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
