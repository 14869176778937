export default function Loader({ loading }) {
  if (loading) {
    return (
      <div className="">
        <div className="dots-9"></div>
      </div>
    );
  }

  return null;
}
