import axios from 'axios';
const BASE_URL = `${process.env.REACT_APP_BASE_URL}`; 
const baseUrl = `${BASE_URL}/api/producten`;

const useProducts = () => { 

    const getAll = async () => {
    const {
      data,
    } = await axios.get(baseUrl, {
    });

    return data.items;
  };

const getById = async (id) => {
  const {
    data
  } = await axios.get(`${baseUrl}/${id}`); 

  return data; 
};

const getBySearch = async (query) => {
  const {
    data
  } = await axios.get(`${baseUrl}/filter?filter=${query}`); 

  return data; 
};

const getByCategory = async (category) => {
  const {
    data
  } = await axios.get(`${baseUrl}/category?category=${category}`); 
  return data; 
};



  return {
    getAll,
    getById,
    getBySearch,
    getByCategory
  };
};

export default useProducts;
  


