import { Link } from "react-router-dom";

function NotFound() {
    return (
        <div className="ml-24 mr-24 min-h-[calc(100vh-208px)]">
      <h1 className="mt-10 mb-2 font-extrabold text-dw-red text-5xl md:text-7xl text-center uppercase">
        Error 404
      </h1>
      <p className="mb-2 font-bold text-dw-red text-xl md:text-2xl text-center uppercase">
        Pagina werd niet gevonden
      </p>
      <Link to="/">
        <div class="flex space-x-2 justify-center">
          <button
            type="button"
            class="bg-dw-red py-2 px-5 text-white rounded hover:contrast-200 transition duration-300"
          >
           <p>HOME</p>
          </button>
        </div>
      </Link>
    </div>
    )
}

export default NotFound
