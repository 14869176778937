import axios from 'axios';

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`; 
const baseUrl = `${BASE_URL}/api/dozen`;

export const getAll = async () => {
  const {
    data
  } = await axios.get(baseUrl); 

  return data.dozen; 
};