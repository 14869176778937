import React from "react";
import { Link } from "react-router-dom";

function Bestelling(bestelling) {

  return (
    <div  >
      <p className="">{bestelling.orderdatum} | Bestelnummer {bestelling.id}</p>
      <Link to={`/user/bestellingen/${bestelling.id}`}>
    <div className="flex flex-col max-w-72 border rounded shadow p-4">
      <div className="">
          <h4 className="font-bold uppercase text-lg tracking-widest">
             Details Bestelling {'>'}
          </h4>
      </div>
      <p className="text-400 truncate">Status: <span style={{ color: bestelling.status === 'verwerkt' ? "orange" : bestelling.status === 'geplaatst' ? 'red' : bestelling.status === 'geleverd' ? 'green' : 'black' }}>{bestelling.status}</span></p>
    </div>
    </Link>
    </div>
  );
}

export default Bestelling;
