import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { CartState } from "../../Context/Context";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`; 
console.log(BASE_URL);

function Product(product) {
  const [quantity, setQuantity] = useState(1);
  const {
    state: { cart },
    dispatch,
  } = CartState();

  const handleChange = (event) => {
    const inputVal = event.target.value;
    if (parseInt(inputVal) > 0 || inputVal === "") {
    setQuantity(parseInt(inputVal));
  }};

  const handleBlur = (event) => {
    if (event.target.value === "") {
      setQuantity(1);
    }
  };

  return (
    <div className="max-w-72  border rounded shadow-lg">
      <div className="border-b h-48">
        <Link to={`/products/${product.id}/${product.name.replace(/ /g, "-")}`}>
          {" "}
          {/* //url moet nog in .env */}
          <img
            src={`${BASE_URL}/images/${product.img}`}
            alt={product.title}
            className="w-full h-full object-scale-down aspect-square"
          />
        </Link>
      </div>
      <div className="p-4">
        <Link to={`/products/${product.id}/${product.name.replace(/ /g, "-")}`}>
          <h4 className="font-bold uppercase text-lg flex h-14">
            {product.name}
          </h4>
        </Link>
        <p className="text-gray-400 mb-4 truncate">{product.description}</p>
        <div className="flex justify-between items-center mb-1">
          <div>
            <button
              onClick={() => setQuantity((prev) => (prev === 1 ? 1 : prev - 1))}
              className="bg-white hover:bg-gray-100 text-gray-400 font-semibold px-2 border-2 border-gray-400 rounded"
            >
              -
            </button>
            <input
              type="number"
              min="0"
              max={product.stock}
              maxLength="3"
              id="quantity-input"
              value={quantity || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              className="inline w-12 text-center border-2 rounded mx-1"
            />
            <button
              onClick={() =>
                quantity < product.stock
                  ? setQuantity((prev) => prev + 1)
                  : toast.error("Te weinig Stock!")
              }
              className="bg-white text-dw-red hover:bg-red-100 font-semibold px-2 border-2 border-dw-red rounded shadow"
            >
              +
            </button>
          </div>
          <h3 className="text-lg font-medium">
            &euro;{" "}
            {product.price.toLocaleString("nl-BE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              useGrouping: true,
              decimalSeparator: ",",
              thousandSeparator: ".",
            })}
          </h3>
        </div>
        <div className="flex justify-between">
          <p className="text-gray-400 font-light text-sm">
            Stock: {product.stock}
          </p>
          <div>
            <button
              className="bg-dw-red py-2 px-6 w-full rounded hover:contrast-200 transition duration-300"
              onClick={() => {
                const productcart = cart.find((prod) => product.id === prod.id);
                if (productcart) {
                  console.log(productcart);
                  if (
                    product.stock !== 0 &&
                    product.stock - (productcart.qty + quantity) >= 0
                  ) {
                    //met find werken constante van die find maken van lijn 69
                    dispatch({
                      type: "ADD_TO_CART",
                      payload: { ...product, qty: quantity },
                    });
                    toast.success(`${product.name} (${quantity}) toegevoegd! `);
                  } else {
                    toast.error("Te weinig Stock!");
                  }
                } else if (
                  product.stock !== 0 &&
                  product.stock - quantity >= 0
                ) {
                  dispatch({
                    type: "ADD_TO_CART",
                    payload: { ...product, qty: quantity },
                  });
                  toast.success(`${product.name} (${quantity}) toegevoegd! `);
                } else {
                  toast.error("Te weinig Stock!");
                }
              }}
            >
              <AiOutlineShoppingCart color="white" size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
