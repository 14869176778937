import axios from 'axios';
import {
  useAuth0,
} from '@auth0/auth0-react';
import {
  useCallback,
} from 'react';
const BASE_URL = `${process.env.REACT_APP_BASE_URL}`; 
const baseUrl = `${BASE_URL}/api/bestellingen`;

const useBestellingen = () => { 

  const {
    getAccessTokenSilently,
  } = useAuth0();

  const getAll = useCallback (async() => {
    const token = await getAccessTokenSilently();
    const {
      data
    } = await axios.get(baseUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return data.bestellingen; 
  }, [getAccessTokenSilently]);

  const getById = async (id) => {
    const token = await getAccessTokenSilently();
    const { data } = await axios.get(`${baseUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }); 
    return data; 
  };

  const getBySearch = async (query) => {
    const token = await getAccessTokenSilently();
    const {
      data
    } = await axios.get(`${baseUrl}/filter?filter=${query}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }); 
  
    return data; 
  };

  const update = async (bestelling) => {
    const token = await getAccessTokenSilently();
    const {
      id,
      ...values
    } = bestelling;
    await axios({
      method: 'PUT',
      url: `${baseUrl}/${id}`,
      data: values,
      headers: {
        Authorization: `Bearer ${token}`,
        }, 
    });
  };

const plaatsen = async (bestelling) => {
  try {
    const token = await getAccessTokenSilently();
    const { ...values } = bestelling;
    const response = await axios({
      method: 'POST',
      url: `${baseUrl}`,
      data: values,
      headers: {
       Authorization: `Bearer ${token}`,
      },
    });

    const orderId  = response.data.bestelling[0].ORDERID; // Extract the orderId from the response data
    return orderId;
  } catch (error) {
    console.log(error);
    throw new Error('Failed to make a new transactie');
  }
};
const getByTnt = async(tntCode,id) => {
  try {
  const {data} = await axios.get(`${baseUrl}/tnt/${tntCode}/${id}`);
  return data;
  }catch(error) {
    console.log(error);
    return null;
  }
};

  return{
    getAll,
    getById,
    update,
    plaatsen,
    getByTnt,
    getBySearch,
  };
}



export default useBestellingen;







