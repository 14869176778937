

import OrderForm from "../Components/OrderForm";

const Order = () => {
  return (
    
        <OrderForm className="mr-10"/>
      
  );
};

export default Order;
